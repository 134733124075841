<template>
  <div class="box">
    <Footer  v-if="!false" :val=arr /> 
    <div class="div1">不使用缓存测试</div>
    <div class="div2"> 不使用缓存测试</div>
    <div class="div3">不使用缓存测试</div>
    <span style="font-weight:700" @click="alertMsg">不适用缓存测试</span>
  </div>
</template>

<script>
import Footer from '../components/Footer'
export default {
  components: {
    Footer
  },
  data() {
    return {
      arr: [{ "text": "test this is test text" }, { "text": "test this is test text" }]
    }
  },
  methods: {
    alertMsg(){
      alert('msg')
    }
  }
}
</script>

<style scoped>
div{
  color: #000 ;
}
.box .div1{
  color: blue ;
}

</style>